<template>
  <v-footer absolute padless id="footer" dark app>
    <v-row no-gutters>
      <v-col cols='12' class="text-center">
        <p class="footer-title">suivez-nous</p>
        <ul class="media-list">
          <li class="media-item">
            <a :href="getFacebookURL" target="_blank"><i class="media-icon fab fa-facebook"></i></a>
          </li>
          <li class="media-item">
            <a :href="getTwitterURL" target="_blank"><i
                class="media-icon fab fa-twitter-square"></i></a>
          </li>
          <li class="media-item">
            <a :href="getInstagramURL" target="_blank"><i class="media-icon fab fa-instagram"></i></a>
          </li>
          <li class="media-item">
            <a :href="getYoutubeURL" target="_blank"><i
                class="media-icon fab fa-youtube-square"></i></a>
          </li>
          <li class="media-item">
            <a href="/contact"><i class="media-icon fas fa-envelope-square"></i></a>
          </li>
        </ul>
        <v-col cols="12">
          <router-link :to="{ name: 'About' }"> <span>A propos</span> </router-link> |
          <a href="/galerie"> <span>Galerie</span> | </a>
          <a href="/contact"> <span>Contact</span> | </a>
          <a href="/actu"> <span>Actualités</span> | </a>
          <router-link :to="{name:'Benevole'}"> <span>Devenir bénévole</span> | </router-link>
          <router-link :to="{name:'InscriptionExposant'}"> <span>Devenir exposant</span> | </router-link>
        </v-col>
        <v-divider/>
        <v-row no-gutters>
          <p class="copyright">
            Copyright &copy;Ludinam {{ new Date().getFullYear() }}. Tous droits réservés.
          </p>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "LudiFooter",
  computed: {
    getFacebookURL() {
      return process.env.VUE_APP_FACEBOOK_URL;
    },
    getInstagramURL() {
      return process.env.VUE_APP_INSTAGRAM_URL;
    },
    getTwitterURL() {
      return process.env.VUE_APP_TWITTER_URL;
    },
    getYoutubeURL() {
      return process.env.VUE_APP_YOUTUBE_URL;
    },
  }
}
</script>

<style scoped>
a{
  text-decoration: none;
  color: #f8f4f4 !important;
}
</style>