<template>
  <v-row align="center" justify="center" v-show="lastThreeArticles.length">
    <v-col cols="12"><h1>Dernières nouvelles</h1></v-col>
    <v-col
        v-for="(article, index) in lastThreeArticles"
        :key="index"
        cols="12" sm="4"
    >
      <v-card :to="{ name: 'Article', params:{articleId:article.id} }">
        <v-img :src="getFullURL(article.mediaObjectImage)" cover max-height="250px" />
        <v-card-title class="card-title-2 ludi-orange white--text" v-text="article.titre">
        </v-card-title>
        <v-spacer></v-spacer>
<!--        <v-card-text v-html="article.contenu" style="height: 100px" class="not-more-than-3-lines"></v-card-text>-->
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-spacer/>
          <router-link class="link" :to="{ name: 'Article', params:{articleId:article.id} }">
            Lire la suite...
          </router-link>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ArticleMixin from "../../mixins/ArticleMixin";
import MediaObjectMIxin from "../../mixins/MediaObjectMixin";

export default {
  name: "LastThreeArticles",
  mixins:[ArticleMixin, MediaObjectMIxin],
  created() {
    this.loadLastThreeArticles();
  }
}
</script>

<style scoped>
.not-more-than-3-lines {
  line-height: 1.4em;
  height: 4.5em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}
</style>