import Vue from "vue";
import Vuex from "vuex";
import makeCrudModule from "./modules/crud";
import actifEvenement from "./modules/actifEvenement";
import notifications from "./modules/notifications";
import evenementService from "../services/evenement";
import siteParamService from "../services/siteParams";
import {article} from "./modules/article.module";
import {navigation} from "./modules/navigation.module";
import {partenaire} from "./modules/partenaire.module"

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        notifications,
        article,
        partenaire,
        navigation,
        evenement: makeCrudModule({
            service: evenementService
        }),
        siteParams: makeCrudModule({
            service: siteParamService
        }),
        actifEvenement: actifEvenement(),
        strict: process.env.NODE_ENV !== "production"
    },
});

export default store;
