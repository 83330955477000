import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import {loadCurrentEvent} from './bootstrap'
import {initRoutes} from './router'
import moment from "moment";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.filter('truncate', function (text) {
    if (!text){
        return '';
    }
    return text.length > 12 ? text.slice(0, 9) + '...' : text
})
Vue.filter('moment', function (date) {
    function padTo2Digits(num) {
        return String(num).padStart(2, '0');
    }
    let dDate = new Date(date);
    return padTo2Digits(dDate.getHours()) +':'+padTo2Digits(dDate.getMinutes());
    return
})
let currentEventLoaded = null;
loadCurrentEvent()
    .then(currentEvent => {
        const routes = initRoutes({
            currentEvent: !!currentEvent,
            ticketing: currentEvent && currentEvent.ticketing
        });
        Vue.prototype.$announcedEvent = currentEvent;
        new Vue({
            router: new VueRouter({
                mode: "history",
                routes}),
            store,
            vuetify,
            render: h => h(App)
        }).$mount('#app');

    });