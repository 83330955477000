import { mapFields } from 'vuex-map-fields';
import {mapActions} from "vuex";

export default {
    computed: {
        ...mapFields('article', ['lastThreeArticles', 'articles','totalArticles', 'currentArticle']),
        maxNbPages(){
            return Math.round(this.totalArticles/10);
        }
    },
    methods: {
        ...mapActions({
            loadLastThreeArticles: "article/getLastThreeArticles",
            loadArticle :"article/getArticle"
        })
    }
}