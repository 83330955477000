import {getField, updateField} from 'vuex-map-fields';
import PartenaireService from "../../services/partenaire.service"

export const partenaire = {
    namespaced: true,
    state: {
        categories: [],
        totalCategories: 0,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {
        async getCategories({state}, payload) {
            return new Promise((resolve, reject) => {

                let page = payload && payload.page != undefined ? payload.page : 1;
                let itemsPerPage = payload && payload.itemsPerPage != undefined ? payload.itemsPerPage : 50;
                PartenaireService.getCategories({page: page, itemsPerPage: itemsPerPage}).then(response => {
                    state.categories = response.data['hydra:member'];
                    state.totalCategories = response.data['hydra:totalItems'];
                    resolve(state.categories);
                }).catch(e => {
                    console.error(e);
                    reject(e);
                })
            })

        }
    }
}