<template>
  <v-app-bar id="navbar" app dark   absolute>
      <router-link :to="{ name: 'Home' }">
        <v-img
            alt="Ludinam logo"
            class=""
            contain
            src="../assets/image/ludinam_2019.png"
            transition="scale-transition"
            width="200"
            min-width="100"
        />
      </router-link>
    <v-list-item v-if="!$vuetify.breakpoint.mobile" v-for="(item, index) in items" :key="index"
                 :class="currentItemName == item.name ? 'active' : ''" :to="(item.items=== undefined)?{ name: item.name }:null">
      <v-list-item-title v-if="!item.items">
        {{ item.label }}
      </v-list-item-title>
      <v-menu v-else>
        <template v-slot:activator="{on}">
          <v-list-item-title v-on="on">
            <a>{{ item.label }}</a>
          </v-list-item-title>
        </template>
        <v-list>
          <v-list-item
              v-for="(childItem, i) in item.items"
              :key="i"
              :to="{ name: childItem.name }"
          >
            <v-list-item-title>{{ childItem.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
    <v-divider light/>
    <v-btn icon @click="showNavigation=!showNavigation" v-show="$vuetify.breakpoint.mobile">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {initMenuItems} from "../router";
import { mapFields } from 'vuex-map-fields';

export default {
  name: "Ludinavbar",
  data() {
    return {
    }
  },
  created() {
    this.items = initMenuItems({
      currentEvent: !!this.$announcedEvent,
      ticketing: this.$announcedEvent && this.$announcedEvent.ticketing
    });
  },
  computed: {
    ...mapFields('navigation', ['showNavigation', 'items']),
    currentItemName() {
      const item = this.items.find(item => {
        return item.name == this.$route.name
            || (item.items && item.items.find(child => child.name == this.$route.name));
      });
      return item ? item.name : null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&family=Roboto:wght@300&display=swap");

$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;
$gris: #666;

#navbar {
  font-family: $font-titre;
  font-weight: bold;
  color: ghostwhite;
  text-transform: uppercase;

  .v-list-item {
    text-align: center;

    &.active,
    &:hover {
      .v-list-item__title {
        border: 1px solid rgb(39, 39, 39);
        background-color: $orange;
        color: white;
      }
    }

    .v-btn {
      .v-btn__content {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .v-list-item__title {
      padding: 15px;

      &:focus {
        background-color: $bleu;
      }

      a {
        color: ghostwhite;
        text-decoration: none;
      }
    }
  }
}
</style>