import { getField, updateField } from 'vuex-map-fields';
import { ACTIONS } from './helpers'; 
import evenementsResource from "../../services/evenement";
import siteParamsResource from "../../services/siteParams";

export default function actifEvenement() {
    return {
        actions: {
            load: ({ commit }) => {
                commit(ACTIONS.SET_LOADING, true);
                siteParamsResource
                    .find('active_event')
                    .then(item => {
                        return evenementsResource.find(item.value.id,{params:{'animations.visible':true}})
                            .then(evenement => Object.assign(evenement, item.value))
                    })
                    .then(item => {
                        commit(ACTIONS.SET_LOADING, false);
                        commit(ACTIONS.SET_ITEM, item);
                    })
                    .catch(e => {
                        commit(ACTIONS.SET_LOADING, false);
                        commit(ACTIONS.SET_ITEM, null);
                    });
          }
        },
        getters: {
            getItem: state => state.item,
            getField
        },
        mutations: {
            updateField,
            [ACTIONS.SET_ITEM]: (state, item) => {
                state.isChanged = state?.item?.id !== item?.id
                Object.assign(state, { item });
            },
            [ACTIONS.SET_LOADING]: (state, isLoading) => {
                Object.assign(state, { isLoading });
            }
        },
        namespaced: true,
        state: {
            item: {},
            isLoading: false,
            isChanged: false
        }
      }
}