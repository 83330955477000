import {getField, updateField} from 'vuex-map-fields';
import ArticleService from "../../services/article.service"

export const article = {
    namespaced: true,
    state: {
        lastThreeArticles: [],
        articles: [],
        currentArticle: null,
        totalArticles: 0
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {
        async getArticle({state}, payload){
          return new Promise((resolve,reject) => {
              let id = payload;
              ArticleService.getArticle(id).then(response => {
                  state.currentArticle = response['data'];
              }).catch(error => {
                  state.currentArticle=null;
                  console.error(error);
              })
          })
        },
        async getLastThreeArticles({state}) {
            if (!state.lastThreeArticles.length)
                return new Promise((resolve, reject) => {
                    ArticleService.getArticles({page: 1, itemsPerPage: 3}).then(response => {
                        state.lastThreeArticles = response.data['hydra:member'];
                        resolve(state.lastThreeArticles);
                    }).catch(e => {
                        console.error(e);
                        reject(e);
                    })
                })

        },
        async getArticles({state}, payload) {
            return new Promise((resolve, reject) => {
                let page = payload && payload.page != undefined ? payload.page : 1;
                let itemsPerPage = payload && payload.itemsPerPage != undefined ? payload.itemsPerPage : 10;
                ArticleService.getArticles({page: page, itemsPerPage: itemsPerPage}).then(response => {
                    state.articles = response.data['hydra:member'];
                    state.totalArticles = response.data['hydra:totalItems'];
                    resolve(state.articles);
                }).catch(e => {
                    console.error(e);
                    reject(e);
                })
            })

        }
    }
}