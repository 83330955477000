import SubmissionError from '../../error/SubmissionError';

const ACTIONS = {
    ADD: 'ADD',
    MERCURE_DELETED: 'MERCURE_DELETED',
    MERCURE_MESSAGE: 'MERCURE_MESSAGE',
    MERCURE_OPEN: 'MERCURE_OPEN',
    RESET_LIST: 'RESET_LIST',
    SET_CREATED: 'SET_CREATED',
    SET_DELETED: 'SET_DELETED',
    SET_ERROR: 'SET_ERROR',
    SET_SELECT_ITEMS: 'SET_SELECT_ITEMS',
    SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
    SET_UPDATED: 'SET_UPDATED',
    SET_VIEW: 'SET_VIEW',
    SET_VIOLATIONS: 'SET_VIOLATIONS',
    TOGGLE_LOADING: 'TOGGLE_LOADING',
    SET_ITEM: 'SET_ITEM',
    SET_LOADING: 'SET_LOADING'
  };

const handleError = (commit, e) => {
    commit(ACTIONS.TOGGLE_LOADING);
  
    if (e instanceof SubmissionError) {
      commit(ACTIONS.SET_VIOLATIONS, e.errors);
      // eslint-disable-next-line
      commit(ACTIONS.SET_ERROR, e.errors._error);
  
      return;
    }
  
    // eslint-disable-next-line
    commit(ACTIONS.SET_ERROR, e.message);
  };
export { ACTIONS, handleError };  