<template>
  <v-container fluid class="ma-0 pa-0">
    <current-event :currentEvent="$announcedEvent" v-if="$announcedEvent"/>
    <v-alert type="info" v-else>Le site est en cours de maintenance, toutes les fonctionnalités ne seront pas forcément accessibles.</v-alert>
    <marquee width="100%" direction="left" height="100px" class="mt-1">
      <img v-for="(item, i) in items" :key="i" height="100px" :src="item.src" contain
           style="max-width: 50vw; max-height: 100px;"
           class="ml-10"/>
    </marquee>
    <v-container :fluid="$vuetify.breakpoint.mobile" class="pa-0 ma-0 ma-md-auto">
      <v-row align="center" ref="secondPart" class="mb-5">
        <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12" sm="4"
        >
          <v-card min-height="200px">
            <v-card-title class="ludi-blue white--text">
              <v-icon color="white">{{ card.icon }}</v-icon>
              <h3>{{ card.title }}</h3>
            </v-card-title>
            <v-card-text class="pa-5" style="min-height: 100px">{{ card.texte }}</v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn dark color="blue" dark class="button" :to="{ name: 'Infos' }">
                En savoir plus
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <last-three-articles class="mb-3"/>
    </v-container>
  </v-container>
</template>

<script>
import moment from "moment";
import CurrentEvent from '../components/Home/CurrentEvent.vue';
import Video from '../components/Infos-utiles/Video.vue';
import {mapActions} from "vuex";
import LastThreeArticles from "../components/Actualite/LastThreeArticles";

export default {
  name: "Home",
  components: {LastThreeArticles, CurrentEvent, Video},
  data: () => ({
    home: {
      title2: "Besancon",
    },
    items: [
      {
        src: require("../assets/image/club.png"),
      },
      {
        src: require("../assets/image/besancon.png"),
      },
      {
        src: require("../assets/image/ca.jpg"),
      },
      {
        src: require("../assets/image/france_3.png"),
      },
      {
        src: require("../assets/image/jdc.png"),
      },
      {
        src: require("../assets/image/bfc.svg"),
      },
    ],
    cards: [],

  }),
  created() {

    moment.locale('fr')
    let dateDeb = moment(this.$announcedEvent.dateDebut)
    let dateFin = moment(this.$announcedEvent.dateFin)
    this.cards = [
      {
        title: "Du " + dateDeb.format('DD') + " au " + moment(this.$announcedEvent.dateFin).format('DD') + " " + moment(this.$announcedEvent.dateFin).format("MMM YYYY"),
        texte:
            "La prochaine édition aura lieu à Besançon du " + dateDeb.format('dddd') + "  " + dateDeb.format('DD') + " au " + dateFin.format('dddd') + "  " + dateFin.format('DD') + " " + dateFin.format("MMM YYYY") + " pour " + (dateFin.diff(dateDeb, 'days') + 1) + " jours de découverte du milieu ludique.",
        icon: "mdi-calendar",
      },
      {
        title: "Prenons le temps de jouer",
        texte:
            "Le festival de jeux Ludinam organisé par le Collectif LUdique Bisontin vous invite à prendre le temps de jouer à Besançon.",
        icon: "mdi-clock",
      },
      {
        title: "A Besancon!",
        texte:
            "Le festival de jeux aura lieu dans de multiples sites de la ville de Besançon en partenariat avec la mairie, un moment unique pour (re-)découvrir la ville!",
        icon: "mdi-compass",
      },
    ]
  },
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

marquee img {
  display: inline;
}

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

.padding-0 {
  padding: 0;
}

.button span a {
  text-decoration: none;
  color: black;
}


.card-title-2 {
  font-family: $font-titre;
  font-weight: bold;
  color: $orange;
}


</style>
