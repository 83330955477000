import axios from 'axios';

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (typeof error.response === 'undefined') {
        console.error(error)
        return  Promise.reject(error.response);
    }
    return Promise.reject(error)
})
console.log('axios ready !')
export default axios