<template>
  <v-row align="center" justify="center">
    <v-list dark>
      <router-link :to="{ name: 'Home' }">
        <v-img
            alt="Vuetify Logo"
            class="shrink ml-4 mb-4"
            contain
            src="../assets/image/ludinam_2019.png"
            transition="scale-transition"
            width="100"
            height="56"
        />
      </router-link>
      <v-list-item
          :to="{ name: 'Home' }"
      >
        <v-list-item-content>
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-for="(item, i) in items">
        <v-list-item
            v-if="!item.items"
            :to="{ name: item.name }"
            :key="i"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.name"/>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
            no-action
            :key="i"
            v-else
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </template>
          <v-list-item
              v-for="(subItem,j) in item.items"
              :key="j"
              link
              :to="{ name: subItem.name }"
          >
            <v-list-item-title v-text="subItem.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-row>
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
  name: "LudiMenu",
  data() {
    return {
      selectedItem: null,
    }
  },
  computed: {
    ...mapFields('navigation', ['showNavigation', 'items']),
  }
}
</script>

<style scoped>

</style>