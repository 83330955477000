import {getField, updateField} from "vuex-map-fields";

export const navigation = {
    namespaced: true,
    state: {
        showNavigation: false,
        items: []
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {}
}
