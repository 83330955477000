<template>
    <div class="countdown">
        <div>
            <span class="countdown-days">{{days}}</span>
            <div>jours</div>
        </div>
        <div>
            <span class="countdown-hours">{{hours}}</span>
            <div>heures</div>
        </div>
        <div>
            <span class="countdown-minutes">{{minutes}}</span>
            <div>minutes</div>
        </div>
        <div>
            <span class="countdown-seconds">{{seconds}}</span>
            <div>secondes</div>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import moment from "moment"

export default {
    name: "Countdown",
    props: ['date'],
    data: () => ({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        interval: null
    }),
    mounted() {
        this.initCountdown();
    },
    methods: {
        setRemainingTime() {
            moment.locale('fr')
            const dateDebut = moment(this.date)
            let duration = moment.duration(dateDebut.diff(moment(), 'seconds'), "seconds")
            this.days = Math.max(Math.round(duration.asDays()),0)
            this.hours = duration.hours()
            this.minutes = duration.minutes()
            this.seconds = duration.seconds()
            if(!this.interval){
                this.interval = setInterval(this.setRemainingTime, 1000)
            }
        },
        initCountdown() {
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.setRemainingTime()
        }
    },
    watch: {
        'date': function () {
            this.initCountdown()
        }
    },
    destroyed() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
</script>

<style scoped>

.countdown {
    display: inline-block;
    padding: 20px;
    background-color: rgba(51, 51, 51, 0.4);
}

.countdown > div {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 2px;
    padding: 10px;
    color: #FFF;
    background-color: #454545;
}

.countdown > div span {
    font-size: 40px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .countdown {
        padding: 5px;
    }

    .countdown > div {
        width: 70px;
        height: 70px;
        padding: 5px;
    }

    .countdown > div span {
        font-size: 25px;
    }
}
</style>