import APIService from "./api.service";

class ArticleService {

    async getArticles(pagination = {}) {
        let url = '/articles?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 20;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage;
        }
        let order = pagination.order != undefined ? pagination.order : 'desc'
        url += '&order%5Bid%5D='+order
        return await APIService.get(url);
    }

    async getArticle(articleId){
        let url ='/articles/'+articleId;
        return await  APIService.get(url);
    }

}

export default new ArticleService();