<template>
    <v-app id="app">
        <v-dialog
                v-model="updateExists"
                width="500"
        >
            <v-card>
                <v-card-title class="white--text ludi-blue">
                    Une mise à jour est disponible !
                </v-card-title>
                <v-card-text>
                    Depuis votre dernière visite, nous avons mis à jour le site de Ludinam, voulez-vous accéder à la
                    dernière version ?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text @click="updateExists=false">Non merci</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue" dark
                            text
                            @click="refreshApp"
                    >
                        Mettre à jour
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-navigation-drawer app clipped dark v-model="showNavigation" v-if="$vuetify.breakpoint.mobile">
            <LudiMenu/>
        </v-navigation-drawer>
        <ludinavbar/>
        <v-main>
            <router-view/>
        </v-main>
        <v-fab-transition>
            <v-btn
                    v-show="!hiddenBtn"
                    style="background-color:rgba(5,146,201,1);"
                    fab
                    dark
                    bottom
                    right
                    fixed
                    class="mr-6 mb-6"
                    @click="$vuetify.goTo(0, { offset: -0 ,duration:1000 })"
            >
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-fab-transition>
        <ludi-footer/>
    </v-app>
</template>

<script>

import Ludinavbar from "./components/Ludinavbar";
import LudiFooter from "./components/LudiFooter";
import {mapFields} from 'vuex-map-fields';
import LudiMenu from "./components/LudiMenu";

export default {
    name: "App",
    components: {LudiMenu, LudiFooter, Ludinavbar},
    data: () => ({
        hiddenBtn: false,
        registration: null,
        updateExists: false,
        refreshing: false,
    }),
    created() {
        window.addEventListener('scroll', this.onScroll, {passive: true})
        document.addEventListener('swUpdated', this.updateAvailable, {once: true});
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload()
        })
        if (localStorage.getItem('newUpdate') === 'new') {
            this.updateExists = true;
        }
    },
    methods: {
        refreshApp() {
            this.updateExists = false;
            if (!localStorage.getItem('newUpdate') !== 'new' && (!this.registration || !this.registration.waiting)) return
            // Send message to SW to skip the waiting and activate the new SW
            localStorage.setItem('newUpdate', 'none')
            this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
        },
        updateAvailable(event) {
            console.log(event);
            this.registration = event.detail
            this.updateExists = true;
        },
        onScroll(e) {
            if (window.scrollY)
                this.hiddenBtn = false
            else
                this.hiddenBtn = true
        }
    },
    computed: {
        ...mapFields('navigation', ['showNavigation']),
    }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&family=Roboto:wght@300&display=swap");

$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;
$gris: #666;
b {
  color: $orange;
}

img {
  max-width: 100vw;
  max-height: 100vh;
}

.link {
  text-decoration: none;
  color: black;
}

.ludi-blue {
  background-color: $bleu !important;
}

.ludi-orange {
  background-color: $orange !important;
}

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

.card-title {
  font-family: $font-titre;
  font-weight: bold;
  color: ghostwhite;
  justify-content: center;
  background-color: $orange;
  display: flex;
  flex-direction: row;

  .title-card {
    font-family: $font-titre;
    font-weight: bold;
    color: ghostwhite;
  }
}

#app {

  #footer {
    .footer-title {
      text-transform: uppercase;
      color: ghostwhite;
      font-size: 21px;
    }

    .media-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      list-style: none;
      font-size: 25px;
      margin: 0;
      padding: 0;

      .media-item {
        padding: 10px;

        .media-icon {
          color: white;
          font-size: 30px;

          &:hover {
            color: $orange;
          }
        }
      }
    }

    .link-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      list-style: none;
      font-size: 15px;
      margin: 0;
      padding: 0;

      .link-item {
        &:hover {
          border-bottom: 1px solid $orange;
        }

        a {
          color: $gris;
          text-decoration: none;

          span {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }

    .copyright {
      color: $gris;
      margin-bottom: unset;
    }
  }
}
</style>
