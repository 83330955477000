import evenementsResource from './services/evenement';
import siteParamsResource from "./services/siteParams";

export async function loadCurrentEvent() {
    return new Promise((resolve) => {
        siteParamsResource.find('active_event')
            .then(item => {
                if (!item || !item.value || !item.value.id) {
                    resolve(item);
                }
                evenementsResource.find(item.value.id,{params:{'animations.visible':true}})
                    .then( (evenement) => {
                        resolve(Object.assign(evenement, item.value))
                    })
            .catch(e => {
                resolve(null);
            });
        });
    });
};

