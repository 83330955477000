import axios from "../plugins/axios";
import store from "../store";
const API_URL = process.env.VUE_APP_API_URL;

class APIService {
    async post(path, data, config) {
        let response;
        try {
            response = await axios.post(API_URL + path, data, config);
        } catch (e) {
            console.error(e)
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }

    async put(path, data) {
        let response;
        try {
            response = await axios.put(API_URL + path, data);
        } catch (e) {
            console.error(e)
            return Promise.reject(e.response);
        }

        return Promise.resolve(response);
    }

    async get(path) {
        let response;
        try {
            response = await axios.get(API_URL + path);
        } catch (e) {
            console.error(e)
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }

    async delete(path) {
        let response;
        try {
            response = await axios.delete(API_URL + path);
        } catch (e) {
            console.error(e);
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }
}

export default new APIService();