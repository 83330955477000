<template>
  <v-row id="promote-event" align="center" dense v-bind:style="{ 'background-image': 'url(' + backgroundImage + ')' }">
    <v-container class="conteneur-titre" v-if="currentEvent">
      <v-row no-gutters class="fill-height" align="center" justify="center"style="height: 55vh">
      <v-list-item-title id="title" :key="title" v-text="title"/>
      <v-list-item-title id="title2">Besançon</v-list-item-title>
      <v-list-item-title id="title3">centre-ville</v-list-item-title>
      </v-row>
      <v-row align="end" justify="center" style="height: 20vh" v-if="currentEvent">
        <v-col cols="12">
          <countdown v-if="showCountdown" :date="currentEvent.dateDebut"/>
        </v-col>
      </v-row>
    </v-container>
    </v-row>
</template>

<script>
import moment from "moment"
import Countdown from "../Countdown";
import {MEDIA_SERVER} from "../../config/entrypoint";

export default {
  name: "CurrentEvent",
  components: {Countdown},
  props: ['currentEvent'],
  computed: {
    title() {
      moment.locale('fr')
      return moment(this.currentEvent.dateDebut).format('DD') + " - " + moment(this.currentEvent.dateFin).format('DD') + " " + moment(this.currentEvent.dateFin).format("MMM YYYY");
    },
    showCountdown() {
      return moment(this.currentEvent?.dateDebut).isAfter(moment())
    },
    backgroundImage() {
      return this.currentEvent ? MEDIA_SERVER + this.currentEvent.imageUrl : "../assets/image/header_img.jpg";
    }
  }
};
</script>


<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

#promote-event {
  height: calc(100vh - 64px);
  width: 100vw;
  background-size: cover;
  background-position: bottom;
  margin: 0;
  padding: 0;
  text-align: center;

  .conteneur-titre {
    padding: 100px;

    #title {
      font-size: 3.8em;
      font-weight: bold;
      color: ghostwhite;
      text-transform: uppercase;
      font-family: 'Rammetto One', cursive;
      text-shadow: 2px 2px 8px #010106;
      white-space: normal;
    }

    #title2 {
      font-size: 3.8em;
      font-weight: bold;
      color: $orange;
      text-transform: uppercase;
      font-family: 'Rammetto One', cursive;
      text-shadow: 2px 2px 8px #010106;
    }
    #title3 {
      font-size: 2em;
      font-weight: bold;
      color: ghostwhite;
      text-transform: uppercase;
      font-family: 'Rammetto One', cursive;
      text-shadow: 2px 2px 8px #010106;
    }
  }

  @media (max-width: 768px) {
    height: calc(100vh - 56px);
    .conteneur-titre {
      padding: 0px;

      #title {
        font-size: 2.3em;
      }

      #title2 {
        font-size: 2.4em;
      }
    }
  }

}

.button span a {
  text-decoration: none;
  color: black;
}

.card-title {
  font-family: $font-titre;
  font-weight: bold;
  color: ghostwhite;
  justify-content: center;
  background-color: $orange;
  display: flex;
  flex-direction: row;

  .title-card {
    font-family: $font-titre;
    font-weight: bold;
    color: ghostwhite;
  }
}


.card-title-2 {
  font-family: $font-titre;
  font-weight: bold;
  color: $orange;
}

.link {
  text-decoration: none;
  color: black;
}

</style>
