import APIService from "./api.service";

class PartenaireService {
    async getCategories(pagination = {}) {
        let url = '/categorie_partenaires?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 20;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage;
        }
        url += '&partenaires.publie=true&order%5Bpriorite%5D=asc'
        return await APIService.get(url);
    }
    async getPartenaire(partenaireId){
        let url ='/partenaires/'+partenaireId;
        return await  APIService.get(url);
    }
}

export default new PartenaireService();