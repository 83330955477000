<template>
  <v-row no-gutters>
    <v-col cols="12" class="text-center">
      <div class="section">
        <h2>Ludinam en 2018 :</h2>
        <br>
        <div id="conteneur">
          <iframe width="829" height="379" src="https://www.youtube.com/embed/uInleflA7GM" frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Video",

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }

  #conteneur {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
